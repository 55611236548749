import React from 'react';

function FluidContent(props) {
    return (
        <section
            id={props.sid}
            className={`container-fluid ${props.className}`}
        >
            {props.children}
        </section>
    );
}

export default FluidContent;