import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import FluidContent from "../components/fluidContent";

const ClinicalTrialsPage = () => {
    return (
        <HcpLayout pageClass="clinical-trials-page">
            <FluidContent className='p-60'>
                <div className="centered-content">
                    <div className="content-wrap">
                        <h1>Clinical Trials</h1>
                        <p><i>For patients in our clinical trials, for any medical questions or concerns, please contact your study doctor (Investigator) or study team.</i></p>
                    </div>
                </div>
            </FluidContent>
        </HcpLayout>
    );
};

export default ClinicalTrialsPage;

export const Head = () => <Seo title="Clinical Trials" />;